import { FaUser } from "react-icons/fa";
import { UseAppContext } from "../../context/appContext";
import { useNavigate } from "react-router-dom";

export function ProfileButton() {
  const { isLogged, translate, isMobile } = UseAppContext();
  const navigate = useNavigate();

  if (!isLogged) {
    return null;
  }

  return (
    <div
      className={isMobile ? "py-2 border-bottom d-flex" : "my-auto me-3 px-0"}
    >
      {isMobile && (
        <FaUser color="var(--text)" size={12} className="my-auto me-2" />
      )}
      <p
        className={"pointer " + (isMobile ? "mb-0" : "my-auto ")}
        onClick={() => {
          navigate("profile");
        }}
      >
        {translate("profile")}
      </p>
    </div>
  );
}
