import { Link, useLocation } from "react-router-dom";
import { UseAppContext } from "../../context/appContext";
import { FaFileContract } from "react-icons/fa";

export function TermsButton() {
  const location = useLocation();
  const { translate, isMobile } = UseAppContext();

  if (location.pathname === "/terms") {
    return null;
  }

  return (
    <div className={isMobile ? "py-2 border-bottom" : "my-auto me-3 px-0"}>
      <Link to="/terms">
        {isMobile ? (
          <FaFileContract color="var(--text)" size={12} className="me-2" />
        ) : null}
        {translate("terms")}
      </Link>
    </div>
  );
}
