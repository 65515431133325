import { Row, Col, Button } from "react-bootstrap";
import { UseAppContext } from "../../context/appContext";
import { MdPlayArrow } from "react-icons/md";
import { useNavigate } from "react-router-dom";

interface Props {
  id?: string;
  title?: string;
  date?: Date;
  course?: string;
}

export default function SimpleMeditationList({
  id,
  title,
  date,
  course,
}: Props) {
  const { translate } = UseAppContext();

  const navigate = useNavigate();

  function goToMeditation() {
    if (course && course?.length) {
      return navigate(`/course/${course}/show/${id}`);
    }

    navigate(`/show/${id}`);
  }

  return (
    <div className="border border-white rounded py-2 px-3 text-start mt-1 mb-2">
      <p className="mb-2 text-14">
        {title} {course && <>({translate("course")})</>}
      </p>
      <Row className="mx-0 ps-0">
        <Col className="mx-0 ps-0 d-flex">
          <p className="mb-1 text-14">{date?.toLocaleDateString()}</p>
        </Col>
        <Col className="mx-0 d-flex pe-0">
          <Button
            variant="outline-accent"
            size="sm"
            className="me-0 ms-auto"
            onClick={() => {
              goToMeditation();
            }}
          >
            {translate("play")}
            <MdPlayArrow />
          </Button>
        </Col>
      </Row>
    </div>
  );
}
