import { useDispatch, useSelector } from "react-redux";
import { UseAppContext } from "../../../context/appContext";
import IHistoryMeditation from "../../../interfaces/IHistoryMeditation";
import { AppDispatch } from "../../../store/actionTypes";
import { useEffect } from "react";
import { fetchHistory } from "../../../store/meditationReducer";
import SimpleMeditationList from "../SimpleMeditationList";

export default function HistoryContainer() {
  const { translate, user } = UseAppContext();

  const dispatch: AppDispatch = useDispatch();
  const myHistory = useSelector(
    (state: any) => state.meditations.history
  ) as IHistoryMeditation[];

  useEffect(() => {
    if (user.id) dispatch(fetchHistory(user.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div
      className="d-flex flex-column text-center px-2"
      style={{ minHeight: "90vh", width: "95vw", maxWidth: "550px" }}
    >
      <h4 className="mb-3">{translate("history")}</h4>
      {myHistory.map((history) => {
        return (
          <SimpleMeditationList
            key={history.id}
            id={history.meditation}
            title={history.title}
            course={history.course}
            date={history.date}
          />
        );
      })}
    </div>
  );
}
