import { Button } from "react-bootstrap";
import { UseAppContext } from "../../context/appContext";

interface Props {
  text: string;
  onClick: () => void;
  selected?: boolean;
  variant?: string;
  textColor?: string;
  children?: React.ReactNode;
  icon?: JSX.Element;
}

export default function Chip({
  text,
  variant = "accent",
  children,
  selected,
  onClick,
  icon,
}: Props) {
  const { isMobile } = UseAppContext();

  return (
    <Button
      variant={variant}
      className={
        "rounded px-2 py-1 m-1 d-flex " + (!selected ? "unselect" : "")
      }
      size="sm"
      style={{ maxHeight: "30px", fontSize: isMobile ? "0.75rem" : undefined }}
      onClick={() => {
        onClick();
      }}
    >
      <div className="my-auto">{icon}</div>
      <p
        className={"my-auto " + (icon ? "ms-2" : "")}
        style={{ color: "initial" }}
      >
        {text}
      </p>
      {children}
    </Button>
  );
}
