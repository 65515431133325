import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { db } from "../../config/firebaseConfig";
import { Spinner } from "react-bootstrap";

export function TermContainer() {
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();
  const [term, setTerm] = useState("<div></div>");

  useEffect(() => {
    const lang = i18n.language;
    async function getTerm() {
      setLoading(true);
      try {
        const termPath = doc(db, "terms", lang);
        const termDoc = await getDoc(termPath);

        if (termDoc.exists()) {
          setTerm(termDoc.data()?.term);
        }
      } catch (error: any) {
        console.error(
          "Error get term:",
          error.response?.data || error.message || error
        );
        setTerm("<div></div>");
      }
      return;
    }

    getTerm().finally(() => {
      setLoading(false);
    });
  }, [i18n.language]);

  return loading ? (
    <Spinner size="sm" color="var(--acent)" className="m-auto" />
  ) : (
    <div
      dangerouslySetInnerHTML={{ __html: term }}
      className="text-start"
      style={{ marginTop: "50px" }}
    />
  );
}
