import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IUser } from "../interfaces/IUser";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../config/firebaseConfig";
import { Timestamp, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

type AppContextType = {
  translate: (key: string) => string;
  width: number;
  height: number;
  isMobile: boolean;
  isLogged: boolean;
  uid: string;
  user: IUser;
  loadingSession: boolean;
  updateUser: (user: IUser) => void;
  updateUserDocument: (user: IUser) => Promise<boolean>;
  logout: () => void;
};

export const AppContext = createContext<AppContextType>({
  translate: () => "",
  width: 700,
  height: 700,
  isMobile: true,
  isLogged: false,
  uid: "",
  user: {} as IUser,
  loadingSession: true,
  updateUser: function (user: IUser): void {
    throw new Error("Function not implemented.");
  },
  updateUserDocument: function (user: IUser): Promise<boolean> {
    return false as any;
  },
  logout: function (): void {},
});

export const UseAppContext = () => useContext(AppContext);

interface Props {
  children: React.ReactNode;
}

export const AppProvider: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [logged, setLogged] = useState(false);
  const [uid, setUid] = useState("");
  const [user, setUser] = useState({} as IUser);
  const [loadingSession, setLoadingSession] = useState(true);

  useEffect(() => {
    try {
      const unsub = onAuthStateChanged(auth, (user) => {
        setLoadingSession(true);
        if (user) {
          user.getIdToken(true);
          if (user.uid.length > 5) {
            const newUser = {} as IUser;
            newUser.id = user.uid;
            newUser.email = user.email || "";
            setUid(user.uid);
            updateUser(newUser);
            setLogged(true);
            getUserData(newUser);
          }
        } else {
          logout();
        }
      });
      return unsub;
    } catch (g) {
      console.error(g);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  async function getUserData(user: IUser) {
    try {
      const userPath = doc(db, "users", user.id || "");
      const userDoc = await getDoc(userPath);

      if (userDoc.exists()) {
        const newUser = {
          id: userDoc.id,
          ...userDoc.data(),
        } as IUser;

        newUser.plan = await getUserPlan(newUser);
        verifyResetMonthMeditations(newUser);
        updateUser(newUser);
        verifyMeditationStreak(newUser);
        setLoadingSession(false);
        return newUser;
      } else {
        return createUserData(user);
      }
    } catch (error: any) {
      setLoadingSession(false);
      console.error(
        "Error get user:",
        error.response?.data || error.message || error
      );
    }
  }

  async function getUserPlan(user: IUser): Promise<string> {
    let plan = "free";
    try {
      const userPath = doc(db, "plans", user.id || "");
      const userDoc = await getDoc(userPath);

      if (userDoc.exists()) {
        plan = userDoc.data()?.plan || "";
      } else {
        createUserPlan(user);
      }
    } catch (error: any) {
      console.error(
        "Error get plan:",
        error.response?.data || error.message || error
      );
    }
    return plan;
  }

  async function verifyResetMonthMeditations(user: IUser) {
    const now = new Date();
    const currentMonth = now.getMonth();
    const lastResetMonth = user?.lastReset?.toDate().getMonth() || undefined;

    if (!lastResetMonth || currentMonth !== lastResetMonth) {
      user.lastReset = Timestamp.fromDate(new Date());
      user.monthMeditations = 0;
      await updateUserDocument(user);
      verifyMeditationStreak(user);
    }
  }

  async function verifyMeditationStreak(user: IUser) {
    const lastMeditation = user?.lastMeditation?.toDate();

    if (!lastMeditation) {
      user.streak = 0;
    } else {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      yesterday.setHours(0, 0, 0, 0);
      lastMeditation.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);

      const lastMeditationDate = lastMeditation.getDate();
      const todayDate = today.getDate();
      const yesterdayDate = yesterday.getDate();

      if (lastMeditationDate === todayDate) {
        return;
      } else if (lastMeditationDate === yesterdayDate) {
        user.streak = user.streak || 0;
      } else {
        user.streak = 0;
      }
    }

    setUser(user);
  }

  async function createUserData(user: IUser) {
    try {
      const userDocPath = doc(db, `users/${user.id}`);
      user.numberOfMeditations = 0;
      await setDoc(userDocPath, user);
      const newUserDocSnap = await getDoc(userDocPath);

      if (newUserDocSnap.exists()) {
        const newUser = {
          id: newUserDocSnap.id,
          ...newUserDocSnap.data(),
          plan: "free",
        } as IUser;

        createUserPlan(newUser);

        updateUser(newUser);
        setLoadingSession(false);
      } else {
        console.error("No se pudo encontrar el documento recién creado");
      }
    } catch (error) {
      console.error("Error agregando el usuario a Firestore: ", error);
    }
  }

  async function createUserPlan(user: IUser) {
    try {
      const userDocPath = doc(db, `plans/${user.id}`);
      user.numberOfMeditations = 0;
      await setDoc(userDocPath, { plan: "free" });
    } catch (error) {
      console.error("Error agregando el usuario a Firestore: ", error);
    }
  }

  function updateUser(user: IUser) {
    setUser(user);
    if (user?.id) {
      setLogged(true);
    }
  }

  async function updateUserDocument(user: IUser) {
    try {
      if (user.id) {
        const userDocPath = doc(db, `users/${user.id}`);
        await updateDoc(userDocPath, user as any);
        updateUser(user);
      }

      return true;
    } catch (error) {
      console.error("Error updating user data in Firestore: ", error);
      return false;
    }
  }

  async function logout() {
    await auth.signOut();
    updateUser({} as IUser);
    setUid("");
    setLogged(false);
    setLoadingSession(false);
  }

  return (
    <AppContext.Provider
      value={{
        translate: t,
        width,
        height,
        isMobile: width < 767,
        isLogged: logged,
        uid,
        user,
        loadingSession,
        updateUser,
        updateUserDocument,
        logout,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
