import AppContainer from "../components/containers/AppContainer";
import HistoryContainer from "../components/meditations/history/HistoryContainer";

export function History() {
  return (
    <AppContainer>
      <HistoryContainer />
    </AppContainer>
  );
}
