/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlay, FaPause, FaRedo, FaHeadphones } from "react-icons/fa";
import { Button } from "react-bootstrap";
import RangeSlider from "react-range-slider-input";
import { UseAppContext } from "../../../context/appContext";
import { IUser } from "../../../interfaces/IUser";
import { Timestamp } from "firebase/firestore";
import TimerOptions from "./TimerOptions";

export function TimePlayer() {
  const birdsAudio = require("../../../assets/audio/birds-river-001.mp3");
  const musicAudio = require("../../../assets/audio/music-001.mp3");
  const bowlAudio = require("../../../assets/audio/bowl-001.mp3");
  const [audioUrl, setAudioUrl] = useState<string>("");
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [meditationEnd, setMeditationEnd] = useState<boolean>(false);
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [meditationMinutes, setMeditationMinutes] = useState<number>(5);
  const [remainingTime, setRemainingTime] = useState<number>(
    meditationMinutes * 60
  );
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const bowlRef = useRef<HTMLAudioElement | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const wakeLockRef = useRef<any>(null);
  const { user, updateUserDocument, translate } = UseAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    setMeditationMinutes(5);
  }, []);

  useEffect(() => {
    if (meditationMinutes) {
      setRemainingTime(meditationMinutes * 60);
    }
  }, [meditationMinutes]);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio && audioUrl) {
      audio.volume = 0.8;
      audio.addEventListener("ended", handleAudioEnd);

      if (isPlaying) {
        if (!(audio.currentTime > 0)) {
          audio.play();
        }
        requestWakeLock();
      } else {
        if (audio.currentTime > 0 && !audio.paused && !audio.ended) {
          audio.pause();
        }
        releaseWakeLock();
      }

      return () => {
        audio.removeEventListener("ended", handleAudioEnd);
      };
    }
  }, [isPlaying]);

  useEffect(() => {
    if (isPlaying && remainingTime > 0) {
      timerRef.current = setInterval(() => {
        setRemainingTime((prev) => prev - 1);
      }, 1000);
    } else if (!isPlaying && timerRef.current) {
      clearInterval(timerRef.current);
    }

    if (remainingTime < 8) {
      const audio = audioRef.current;
      if (audio) {
        audio.volume = remainingTime / 10;
      }
    }

    if (remainingTime <= 0) {
      const bowl = bowlRef.current;
      if (bowl) {
        bowl.play();
      }
      endMeditation();
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isPlaying, remainingTime]);

  async function requestWakeLock() {
    try {
      if ("wakeLock" in navigator) {
        wakeLockRef.current = await (navigator as any).wakeLock.request(
          "screen"
        );
      }
    } catch (err: any) {
      console.error(`${err.name}, ${err.message}`);
    }
  }

  async function releaseWakeLock() {
    if (wakeLockRef.current !== null) {
      await wakeLockRef.current.release();
      wakeLockRef.current = null;
    }
  }

  function handleAudioEnd() {
    const audio = audioRef.current;
    if (audio && audioUrl) {
      audio.currentTime = 0;
      if (isPlaying && remainingTime > 0) {
        audio.play();
      }
    }
  }

  function endMeditation() {
    setMeditationEnd(true);
    setIsPlaying(false);
    addMeditationCount();
  }

  async function addMeditationCount() {
    if (user && user.id) {
      const newUser = { ...user } as IUser;
      newUser.numberOfMeditations = (user?.numberOfMeditations || 0) + 1;
      newUser.lastMeditation = Timestamp.fromDate(new Date());
      newUser.streak = getMeditationNewStreak();
      await updateUserDocument(newUser);
    }
  }

  function getMeditationNewStreak(): number {
    if (user && user.id) {
      const lastMeditation = user?.lastMeditation?.toDate();

      let streak = user.streak || 0;

      if (!lastMeditation) {
        streak = 0;
      } else {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        yesterday.setHours(0, 0, 0, 0);
        lastMeditation.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);

        const lastMeditationDate = lastMeditation.getDate();
        const todayDate = today.getDate();
        const yesterdayDate = yesterday.getDate();

        if (lastMeditationDate === todayDate) {
          return streak;
        } else if (lastMeditationDate === yesterdayDate) {
          streak = (user.streak || 0) + 1;
        } else {
          streak = 0;
        }
      }

      return streak;
    }
    return 0;
  }

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const handleRestart = () => {
    const audio = audioRef.current;
    if (audio && audioUrl) {
      audio.currentTime = 0;
      setIsPlaying(true);
    }
  };

  function goBack() {
    navigate(`/`);
  }

  function rangeEnd(a: any) {
    const newTime = parseFloat(a[1]);
    const newValue = meditationMinutes * 60 - newTime;
    setRemainingTime(newValue);
  }

  function configurationSet(minutes: number, audio: number) {
    setMeditationMinutes(minutes);
    setRemainingTime(minutes * 60);
    setIsStarted(true);

    let currentAudio = audio === 0 ? "" : musicAudio;

    if (audio === 1) {
      currentAudio = birdsAudio;
    }

    setAudioUrl(currentAudio);
  }

  return (
    <div
      className="m-auto border rounded py-3 px-2"
      style={{ width: "90vw", maxWidth: "550px", minHeight: "291px" }}
    >
      {isStarted ? (
        <>
          <div className="mb-4">
            <FaHeadphones color="var(--accent)" size={25} />
            <p>
              <small className="text-accent">
                {translate("useHeadphones")}
              </small>
            </p>
          </div>

          <div className="w-100 px-3 mt-3">
            <div className="">
              <span style={{ fontSize: "1.5rem" }}>
                {formatTime(remainingTime)}
              </span>

              <RangeSlider
                className="single-thumb mt-3"
                thumbsDisabled={[true, false]}
                value={[0, meditationMinutes * 60 - remainingTime]}
                max={meditationMinutes * 60}
                onInput={rangeEnd}
              />
            </div>
            <div>
              <audio ref={audioRef} src={audioUrl} />
              <audio ref={bowlRef} src={bowlAudio} />
              <Button
                variant="accent"
                className="mt-3"
                size="sm"
                onClick={() => {
                  setIsPlaying(!isPlaying);
                }}
              >
                {isPlaying ? <FaPause /> : <FaPlay />}
              </Button>
              {meditationEnd && (
                <Button
                  variant="accent"
                  className="mt-3 ms-2"
                  size="sm"
                  onClick={handleRestart}
                >
                  <FaRedo />
                </Button>
              )}
            </div>
            {meditationEnd ? (
              <div className="mt-4">
                {<p>{translate("congratulationTimer")}</p>}
                <Button
                  variant="outline-accent"
                  className="block mx-2 mt-2"
                  onClick={() => {
                    goBack();
                  }}
                >
                  {translate("back")}
                </Button>
              </div>
            ) : (
              <Button
                className="mt-4"
                variant="outline-white"
                onClick={() => {
                  setIsStarted(false);
                }}
              >
                {translate("configureTimer")}
              </Button>
            )}
          </div>
        </>
      ) : (
        <TimerOptions start={configurationSet} />
      )}
    </div>
  );
}
