import { UseAppContext } from "../../context/appContext";
import { Carousel } from "react-bootstrap";

export default function MeditationsBenefits() {
  const { translate, isMobile } = UseAppContext();

  const benefits = ["benefit1", "benefit2", "benefit3", "benefit4", "benefit5"];

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center border rounded mx-auto mt-4 p-2"
      style={{ width: "90vw", maxWidth: "550px" }}
    >
      <h5 className="mt-2 mb-3">{translate("benefitsOfMeditation")}</h5>

      <div className="mt-0 px-0 text-justify" style={{ minHeight: "100px" }}>
        <Carousel controls={false}>
          {benefits.map((benefit, index) => {
            return (
              <Carousel.Item key={`benfit-car-${index}`}>
                <div
                  style={{
                    height: "auto",
                    minHeight: isMobile ? "200px" : "180px",
                    width: "90vw",
                    maxWidth: "500px",
                  }}
                ></div>
                <Carousel.Caption
                  className="mt-0"
                  style={{ right: "5%", left: "5%", height: "auto" }}
                >
                  <p className="mt-0 text-14">
                    {translate(`benefits.${benefit}`)}
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}
