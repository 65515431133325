import AppContainer from "../components/containers/AppContainer";
import { CompanyLogoInfo } from "../components/brand/CompanyLogoInfo";
import { MeditationPlayer } from "../components/meditations/MeditationPlayer";
import MeditationsBenefits from "../components/meditations/MeditationsBenefits";
import { UseAppContext } from "../context/appContext";
import { TimePlayer } from "../components/meditations/timerMeditation/TimerPlayer";

export function Home() {
  const { translate } = UseAppContext();

  return (
    <AppContainer>
      <div style={{ marginTop: "50px" }} className="bg-back pb-5">
        <CompanyLogoInfo />
        <MeditationPlayer />
        <h6 className="mt-3 mb-3">{translate("comeBackTomorrow")}</h6>
        <TimePlayer />
        <MeditationsBenefits />
      </div>
    </AppContainer>
  );
}
