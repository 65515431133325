import { Button, Form } from "react-bootstrap";
import { UseAppContext } from "../../../context/appContext";
import { useEffect, useState } from "react";
import { FaRegMinusSquare, FaRegPlusSquare } from "react-icons/fa";

interface Props {
  start: (minutes: number, audio: number) => void;
}

export default function TimerOptions({ start }: Props) {
  const { translate } = UseAppContext();
  const [minutes, setMinutes] = useState(5);
  const [audio, setAudio] = useState(1);
  const [audioName, setAudioName] = useState("");

  function changeAudio(left: boolean) {
    if (left) {
      const newAudio = audio - 1;
      setAudio(newAudio < 0 ? 2 : newAudio);
      return;
    }

    const newAudio = audio + 1;
    setAudio(newAudio > 2 ? 0 : newAudio);
  }

  useEffect(() => {
    if (audio === 0) {
      return setAudioName(translate("audios.none"));
    }

    setAudioName(translate(audio === 1 ? "audios.birds" : "audios.music"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio]);

  return (
    <div className="mx-4">
      <h5 className="mb-3">{translate("meditateByTime")}</h5>

      <Form.Group className="mb-3" controlId="userName">
        <Form.Label>
          {translate("duration")} ({translate("minutes")})
        </Form.Label>
        <div className="d-flex">
          <Button
            variant="back"
            onClick={() => {
              if (minutes > 1) {
                setMinutes(minutes - 1);
              }
            }}
          >
            <FaRegMinusSquare size={25} />
          </Button>

          <Form.Control
            type="number"
            placeholder={translate("minutes")}
            value={minutes}
            min={1}
            className="text-center"
            onChange={(v) => {
              setMinutes(Number(v.currentTarget.value));
            }}
          />

          <Button
            variant="back"
            onClick={() => {
              setMinutes(minutes + 1);
            }}
          >
            <FaRegPlusSquare size={25} />
          </Button>
        </div>

        {minutes < 1 && (
          <Form.Text style={{ color: "var(--accent)" }}>
            {translate("error")}
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Label>{translate("sound")}</Form.Label>

        <div className="d-flex mb-4">
          <Button
            variant="back"
            onClick={() => {
              changeAudio(true);
            }}
          >
            <FaRegMinusSquare size={25} />
          </Button>

          <Form.Control
            type="text"
            placeholder={translate("audio")}
            readOnly
            value={audioName}
            className="text-center"
          />

          <Button
            variant="back"
            onClick={() => {
              changeAudio(false);
            }}
          >
            <FaRegPlusSquare size={25} />
          </Button>
        </div>
      </Form.Group>

      <Button
        variant="outline-accent"
        size="sm"
        disabled={minutes < 1}
        onClick={() => {
          start(minutes, audio);
        }}
      >
        {translate("startMeditation")}
      </Button>
    </div>
  );
}
