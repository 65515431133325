import AppContainer from "../components/containers/AppContainer";
import ProfileContainer from "../components/user/ProfileContainer";

export function Profile() {
  return (
    <AppContainer>
      <ProfileContainer />
    </AppContainer>
  );
}
