import { UseAppContext } from "../../context/appContext";
import Logo from "../../assets/logo/logo-256.png";

export function CompanyLogoInfo() {
  const { translate } = UseAppContext();

  return (
    <div
      className="mx-auto px-2 mb-3"
      style={{ width: "90vw", maxWidth: "550px" }}
    >
      <img src={Logo} alt="fluirZen logo" className="mx-auto" width={80} />
      <h1 className="mt-1 titles text-24">{translate("appName")}</h1>
      <h6 className="mb-2 text-14">{translate("homeSubtitle")}</h6>
    </div>
  );
}
