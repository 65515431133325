import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ref, getDownloadURL } from "firebase/storage";
import { FaPlay, FaPause, FaHeadphones, FaUndo } from "react-icons/fa";
import { storage } from "../../config/firebaseConfig";
import IMeditation from "../../interfaces/IMeditation";
import { Button, Spinner } from "react-bootstrap";
import RangeSlider from "react-range-slider-input";
import {
  addToFavorites,
  addToHistory,
  fetchTodayMeditation,
  isFavorited,
} from "../../store/meditationReducer";
import { AppDispatch } from "../../store/actionTypes";
import { UseAppContext } from "../../context/appContext";
import { IUser } from "../../interfaces/IUser";
import { Timestamp } from "firebase/firestore";
import IHistoryMeditation from "../../interfaces/IHistoryMeditation";
import IFavoritesMeditation from "../../interfaces/IFavoritesMeditation";
import { MdBookmark, MdOutlineBookmarkBorder } from "react-icons/md";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export function MeditationPlayer() {
  const [currentMeditation, setCurrentMeditation] =
    useState<IMeditation | null>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [meditationEnd, setMeditationEnd] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const { user, updateUserDocument, translate } = UseAppContext();
  const wakeLockRef = useRef<any>(null);
  const { i18n } = useTranslation();
  const currentLanguage =
    user?.lang || localStorage.getItem("lang") || i18n.language;

  const storedMeditation = useSelector(
    (state: any) => state.meditations.meditation
  ) as IMeditation;

  useEffect(() => {
    getMeditation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  function getMeditation() {
    dispatch(fetchTodayMeditation({ lang: currentLanguage || "pt" }));
  }

  useEffect(() => {
    setCurrentMeditation(storedMeditation);
    if (storedMeditation.audio) {
      getAudioUrl(storedMeditation.audio, `${storedMeditation.id}.mp3`).then(
        (url) => {
          setAudioUrl(url);
        }
      );
    }
    getIsFavorite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedMeditation]);

  async function getIsFavorite() {
    const idMeditation = storedMeditation.id || id;
    if (user.id && idMeditation) {
      const response = await isFavorited(user.id, idMeditation);
      setFavorite(response);
    }
  }

  useEffect(() => {
    if (audioUrl) {
      getAudioDuration(audioUrl).then((duration) => {
        setDuration(duration);
      });
    }
  }, [audioUrl]);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      audio.volume = 1;
    }

    const handleTimeUpdate = () => {
      const time = audioRef.current?.currentTime || 0;
      setCurrentTime(time);

      if (audio) {
        const remainingTime = duration - time;
        if (remainingTime < 8) {
          audio.volume = remainingTime / 10;
        }
      }

      if (audio && audio.ended) {
        endMeditation();
      }
    };

    if (audio) {
      audio.addEventListener("timeupdate", handleTimeUpdate);

      if (isPlaying) {
        if (!(audio.currentTime > 0 && !audio.paused && !audio.ended)) {
          audio.play();
        }
        requestWakeLock();
      } else {
        if (audio.currentTime > 0 && !audio.paused && !audio.ended) {
          audio.pause();
        }
        releaseWakeLock();
      }

      return () => {
        audio.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  function endMeditation() {
    setMeditationEnd(true);
    addMeditationCount();

    if (user.id) {
      const meditationHistory = {
        meditation: currentMeditation?.id,
        date: new Date(),
        title: currentMeditation?.title,
      } as IHistoryMeditation;
      addToHistory(user.id, meditationHistory);
    }
  }

  async function addToMyFavorites() {
    if (user.id) {
      const meditationFavorite = {
        meditation: currentMeditation?.id || id || "",
        date: new Date(),
        title: currentMeditation?.title,
      } as IFavoritesMeditation;

      const response = await addToFavorites(user.id, meditationFavorite);

      if (response) {
        getIsFavorite();

        toast.success(translate("success"), {
          position: "top-center",
          autoClose: 1500,
        });
        return;
      }

      toast.error(translate("error"), {
        position: "top-center",
        autoClose: 1500,
      });
    }
  }

  async function addMeditationCount() {
    if (user && user.id) {
      const newUser = { ...user } as IUser;
      newUser.numberOfMeditations = (user?.numberOfMeditations || 0) + 1;
      newUser.lastMeditation = Timestamp.fromDate(new Date());
      newUser.streak = getMeditationNewStreak();
      await updateUserDocument(newUser);
    }
  }

  function getMeditationNewStreak(): number {
    const lastMeditation = user?.lastMeditation?.toDate();

    let streak = user.streak || 0;

    if (!lastMeditation) {
      streak = 0;
    } else {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      yesterday.setHours(0, 0, 0, 0);
      lastMeditation.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);

      const lastMeditationDate = lastMeditation.getDate();
      const todayDate = today.getDate();
      const yesterdayDate = yesterday.getDate();

      if (lastMeditationDate === todayDate) {
        return streak;
      } else if (lastMeditationDate === yesterdayDate) {
        streak = (user.streak || 0) + 1;
      } else {
        streak = 0;
      }
    }

    return streak;
  }

  async function requestWakeLock() {
    try {
      if ("wakeLock" in navigator) {
        wakeLockRef.current = await (navigator as any).wakeLock.request(
          "screen"
        );
      }
    } catch (err: any) {
      console.error(`${err.name}, ${err.message}`);
    }
  }

  async function releaseWakeLock() {
    if (wakeLockRef.current !== null) {
      await wakeLockRef.current.release();
      wakeLockRef.current = null;
    }
  }

  function openDatabase(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("audioDatabase", 1);

      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const db = (event.target as IDBOpenDBRequest).result;
        db.createObjectStore("audios", { keyPath: "fileName" });
      };

      request.onsuccess = (event: Event) => {
        resolve((event.target as IDBOpenDBRequest).result);
      };

      request.onerror = (event: Event) => {
        reject((event.target as IDBOpenDBRequest).error);
      };
    });
  }

  async function getAudioDuration(url: string): Promise<number> {
    return new Promise((resolve) => {
      const audio = new Audio(url);
      audio.onloadedmetadata = () => {
        resolve(audio.duration || 0);
      };
      audio.src = url;
    });
  }

  async function storeAudioInDB(
    fileName: string,
    blob: Blob
  ): Promise<boolean> {
    const db = await openDatabase();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction("audios", "readwrite");
      const store = transaction.objectStore("audios");

      const request = store.put({ fileName, blob });

      request.onsuccess = () => {
        resolve(true);
      };

      request.onerror = (event: Event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  async function getAudioFromDB(fileName: string): Promise<Blob | null> {
    const db = await openDatabase();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction("audios", "readonly");
      const store = transaction.objectStore("audios");

      const request = store.get(fileName);

      request.onsuccess = (event: Event) => {
        const result = (event.target as IDBRequest).result;
        resolve(result ? result.blob : null);
      };

      request.onerror = (event: Event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  async function fetchAndStoreAudio(
    storagePath: string,
    fileName: string
  ): Promise<string> {
    try {
      const audioRef = ref(storage, storagePath);
      const url = await getDownloadURL(audioRef);
      const response = await fetch(url);
      const blob = await response.blob();

      await storeAudioInDB(fileName, blob);

      return URL.createObjectURL(blob);
    } catch (error) {
      console.error("Error fetching audio:", error);
      throw error;
    }
  }

  async function getAudioUrl(
    storagePath: string,
    fileName: string
  ): Promise<string> {
    const storedBlob = await getAudioFromDB(fileName);
    if (storedBlob) {
      return URL.createObjectURL(storedBlob);
    } else {
      return fetchAndStoreAudio(storagePath, fileName);
    }
  }

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const handleRestart = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = 0;
      if (isPlaying) {
        audio.play();
      }
    }
  };

  function rangeEnd(a: any) {
    const audioElement = audioRef.current;

    if (audioElement) {
      const newTime = parseFloat(a[1]);
      audioElement.currentTime = newTime;
      setCurrentTime(newTime);
    }
  }

  return (
    <div
      className="m-auto border rounded py-3 px-2"
      style={{ width: "90vw", maxWidth: "550px", minHeight: "291px" }}
    >
      <h5 className="mb-2">{translate("todayMeditation")}</h5>
      <h6 className="mb-4 mt-0">{currentMeditation?.title}</h6>
      <div className="mb-4">
        <FaHeadphones color="var(--accent)" size={25} />
        <p>
          <small className="text-accent">{translate("useHeadphones")}</small>
        </p>
      </div>
      {audioUrl ? (
        <div className="w-100 px-3 mt-3">
          <div className="">
            <span style={{ fontSize: "1.5rem" }}>
              {formatTime(duration - currentTime)}
            </span>

            <RangeSlider
              className="single-thumb mt-3"
              thumbsDisabled={[true, false]}
              value={[0, currentTime]}
              max={duration}
              onInput={rangeEnd}
            />
          </div>
          <div>
            <audio ref={audioRef} src={audioUrl} />
            <Button
              variant="accent"
              className="mt-3"
              size="sm"
              onClick={() => setIsPlaying(!isPlaying)}
            >
              {isPlaying ? <FaPause /> : <FaPlay />}
            </Button>

            {meditationEnd && (
              <Button
                variant="accent"
                className="mt-3 ms-2"
                size="sm"
                onClick={handleRestart}
              >
                <FaUndo />
              </Button>
            )}

            {user.id && (
              <Button
                variant={favorite ? "accent" : "outline-white"}
                className="mt-3 ms-2"
                size="sm"
                onClick={() => addToMyFavorites()}
              >
                {favorite ? (
                  <MdBookmark size={16} />
                ) : (
                  <MdOutlineBookmarkBorder size={16} />
                )}
              </Button>
            )}
          </div>
          {meditationEnd && currentMeditation?.congratulation && (
            <div className="mt-4">
              {<p>{currentMeditation?.congratulation}</p>}
            </div>
          )}
        </div>
      ) : (
        <Spinner color="var(--text)" size="sm" />
      )}
    </div>
  );
}
