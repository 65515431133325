import { changeLanguage } from "i18next";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UseAppContext } from "../../context/appContext";
import { FaChevronDown, FaLanguage } from "react-icons/fa";
import { useEffect, useState } from "react";
import { IUser } from "../../interfaces/IUser";

interface Props {
  hideIcon?: boolean;
}

const LangSelect = ({ hideIcon }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const { i18n } = useTranslation();
  const { isMobile, translate, user, updateUserDocument } = UseAppContext();
  const currentLanguage =
    user?.lang || localStorage.getItem("lang") || i18n.language;

  useEffect(() => {
    handleLanguageChange(currentLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newUserLang = user?.lang;
    if (newUserLang) {
      changeLanguage(newUserLang);
      localStorage.setItem("lang", newUserLang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleLanguageChange = (lang: string) => {
    localStorage.setItem("lang", lang);
    changeLanguage(lang);
    setOpenModal(false);
    const newUser = { ...user } as IUser;
    newUser.lang = lang;
    updateUserDocument(newUser);
  };

  return (
    <div
      className={
        (isMobile ? "py-2 d-flex " : "my-auto me-3 px-1 ") +
        (hideIcon || !isMobile ? "" : "border-bottom")
      }
    >
      <div
        className="my-auto pointer"
        onClick={() => {
          setOpenModal(!openModal);
        }}
      >
        {isMobile && !hideIcon && (
          <FaLanguage color="var(--text)" size={12} className="my-auto me-2" />
        )}

        <span>{currentLanguage?.toUpperCase()} </span>
        <FaChevronDown color="var(--text)" size={5} />
      </div>

      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={openModal}
        onHide={() => setOpenModal(false)}
      >
        <Modal.Body className="d-flex flex-column bg-back py-4 rounded">
          <h4 className="mb-4">{translate("selectLang")}</h4>
          <Button
            variant="outline-white"
            className="block"
            onClick={() => handleLanguageChange("es")}
          >
            {translate("languages.es")}
          </Button>
          <Button
            variant="outline-white"
            className="block mt-3"
            onClick={() => handleLanguageChange("pt")}
          >
            {translate("languages.pt")}
          </Button>
          <Button
            variant="outline-white"
            className="block mt-3"
            onClick={() => handleLanguageChange("en")}
          >
            {translate("languages.en")}
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LangSelect;
