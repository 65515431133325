import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import userReducer from "./userReducer";
import meditationReducer from "./meditationReducer";

export const store = configureStore({
  reducer: combineReducers({
    user: userReducer,
    meditations: meditationReducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
