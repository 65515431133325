import { Link, useLocation } from "react-router-dom";
import { UseAppContext } from "../../context/appContext";
import { FaHome } from "react-icons/fa";

export function HomeButton() {
  const location = useLocation();
  const { translate, isMobile } = UseAppContext();

  if (location.pathname === "/") {
    return null;
  }

  return (
    <div className={isMobile ? "py-2 border-bottom" : "my-auto me-3 px-0"}>
      <Link to="/">
        {isMobile ? (
          <FaHome color="var(--text)" size={12} className="me-2" />
        ) : null}
        {translate("home")}
      </Link>
    </div>
  );
}
