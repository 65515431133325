import { Button, Form, Spinner } from "react-bootstrap";
import { UseAppContext } from "../../context/appContext";
import { useEffect, useState } from "react";
import IChipItem from "../../interfaces/IChipItem";
import Chip from "../basics/Chip";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function ProfileContainer() {
  const { user, isLogged, translate, updateUserDocument, loadingSession } =
    UseAppContext();
  const [currentUser, setCurrentUser] = useState(user);
  const [name, setName] = useState("");
  const [valid, setValid] = useState(false);
  const [experienceItems, setExperienceItems] = useState([] as IChipItem[]);
  const [genderItems, setGenderItems] = useState([] as IChipItem[]);
  const [currentExperience, setCurrentExperience] = useState(
    user?.meditationExperience || ""
  );

  const [currentGender, setCurrentGender] = useState(user?.gender || "");
  const [firstUpdate, setFirstUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      !loadingSession &&
      user &&
      (!user?.name || !user?.meditationExperience)
    ) {
      setFirstUpdate(true);
    } else {
      setFirstUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLogged, loadingSession]);

  useEffect(() => {
    const experiences = [
      { id: "beginner", selected: false },
      { id: "intermediate", selected: false },
      { id: "advanced", selected: false },
    ] as IChipItem[];
    setExperienceItems(experiences);

    const genders = [
      { id: "female", selected: false },
      { id: "male", selected: false },
      { id: "other", selected: false },
    ] as IChipItem[];

    setGenderItems(genders);
  }, []);

  useEffect(() => {
    setCurrentUser(user);
    if (user.name) {
      setName(user.name);
    }

    if (user?.meditationExperience) {
      const experience = experienceItems.find(
        (item) => item.id === user.meditationExperience
      );

      if (experience) {
        setSelectedExperience(experience);
      }
    }

    if (user?.gender) {
      const gender = genderItems.find((item) => item.id === user.gender);

      if (gender) {
        setSelectedGender(gender);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLogged, loadingSession, name]);

  useEffect(() => {
    if (name?.length > 1 && currentExperience?.length > 4) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [name, currentExperience]);

  async function updateUserProfile() {
    setLoading(true);
    currentUser.name = name;
    currentUser.meditationExperience = currentExperience;
    currentUser.gender = currentGender;
    const response = await updateUserDocument(currentUser);
    if (!response) {
      toast.error(translate("error"), {
        position: "top-center",
        autoClose: 1500,
      });
    }
    if (firstUpdate) {
      navigate(-1);
    }
    setLoading(false);
  }

  function setSelectedExperience(selectedItem: IChipItem) {
    const updatedItems = experienceItems.map((item) => ({
      ...item,
      selected: item.id === selectedItem.id,
    }));
    setCurrentExperience(selectedItem.id);
    setExperienceItems(updatedItems);
  }

  function setSelectedGender(selectedItem: IChipItem) {
    const updatedItems = genderItems.map((item) => ({
      ...item,
      selected: item.id === selectedItem.id,
    }));
    setCurrentGender(selectedItem.id);
    setGenderItems(updatedItems);
  }

  return (
    <div
      className="d-flex flex-column text-center"
      style={{ minHeight: "90vh", width: "95vw", maxWidth: "550px" }}
    >
      <div className="border-white border rounded p-2 m-auto mx-2">
        <div
          className="d-flex bg-back py-2"
          style={{ border: "1px solid var(--back)" }}
        >
          <h4 className="m-auto">{translate("myProfile")}</h4>{" "}
          {loading && <Spinner size="sm" className="ms-3 my-auto" />}{" "}
        </div>

        <div className="d-flex flex-column bg-back">
          {!valid && <p>{translate("pleaseFillProfile")}</p>}

          <Form.Group className="mb-3" controlId="userName">
            <Form.Label>{translate("name")}</Form.Label>
            <Form.Control
              isInvalid={!name?.length}
              type="text"
              placeholder={translate("name")}
              required
              value={name}
              onChange={(v) => {
                setName(v.currentTarget.value);
              }}
            />
            {!name?.length && (
              <Form.Text style={{ color: "var(--accent)" }}>
                {translate("emptyNameError")}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="userExperience">
            <Form.Label>{translate("meditationExperience")}</Form.Label>
            <div className="d-flex mx-auto justify-content-center">
              {experienceItems.map((item) => {
                return (
                  <Chip
                    key={`profile-${item.id}`}
                    variant="text"
                    text={translate(item.id)}
                    selected={item.selected}
                    onClick={() => {
                      setSelectedExperience(item);
                    }}
                  />
                );
              })}
            </div>
            {!currentExperience?.length && (
              <Form.Text style={{ color: "var(--accent)" }}>
                {translate("emptyExperience")}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="genderExperience">
            <Form.Label>{translate("gender")}</Form.Label>
            <div className="d-flex mx-auto justify-content-center">
              {genderItems.map((item) => {
                return (
                  <Chip
                    key={`gender-${item.id}`}
                    variant="text"
                    text={translate(item.id)}
                    selected={item.selected}
                    onClick={() => {
                      setSelectedGender(item);
                    }}
                  />
                );
              })}
            </div>
          </Form.Group>
        </div>
        <div
          className="d-flex bg-back"
          style={{ border: "1px solid var(--back)" }}
        >
          <Button
            variant="accent"
            className="block mt-1"
            disabled={!valid || loading}
            onClick={() => {
              updateUserProfile();
            }}
          >
            {translate("updateProfile")}
          </Button>
        </div>
      </div>
    </div>
  );
}
