import { useLocation } from "react-router-dom";
import AppContainer from "../components/containers/AppContainer";
import { MeditationPlayer } from "../components/meditations/MeditationPlayer";
import { TimePlayer } from "../components/meditations/timerMeditation/TimerPlayer";

export function ShowMeditation() {
  const location = useLocation();
  const timer =
    location.pathname === "/timer" || location.pathname === "/timerIframe";

  return (
    <AppContainer hideBar={true}>
      {timer ? <TimePlayer /> : <MeditationPlayer />}
    </AppContainer>
  );
}
