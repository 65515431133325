import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserState } from "./actionTypes";
import { IUser } from "../interfaces/IUser";

const initialState: UserState = {
  user: {} as IUser,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setStoreUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
  },
});

export const { setStoreUser } = userSlice.actions;
export default userSlice.reducer;
