import { useEffect, useState } from "react";
import { UseAppContext } from "../../context/appContext";
import LangSelect from "../lang/LangSelect";
import { HomeButton } from "./HomeButton";
import { changeLanguage } from "i18next";
import { LogoutButton } from "./LogoutButton";
import { Button } from "react-bootstrap";
import { FaBars, FaChevronLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { TermsButton } from "./TermsButton";
import { ProfileButton } from "./ProfileButton";

export function AppNavbar() {
  const [open, setOpen] = useState(false);
  const [menuHeight, setMenuHeight] = useState("auto");
  const { isMobile, user } = UseAppContext();
  const location = useLocation();
  const canBack = location.pathname !== "/";
  const navigate = useNavigate();

  useEffect(() => {
    const newUserLang = user?.lang;
    if (newUserLang) {
      changeLanguage(newUserLang);
      localStorage.setItem("lang", newUserLang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  function goToHome() {
    navigate(-1);
  }

  useEffect(() => {
    if (!isMobile) {
      setOpen(true);
    }

    if (isMobile && open) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  useEffect(() => {
    if (open) {
      setMenuHeight("150px");
    } else {
      setMenuHeight("28px");
    }
  }, [open]);

  return (
    <div
      className="d-flex bg-back custom-navbar"
      style={{
        height: open ? "auto" : "40px",
        minHeight: open ? "40px" : undefined,
        width: "100%",
        position: "fixed",
        zIndex: "200",
      }}
    >
      {canBack ? (
        <Button
          variant="transparent"
          onClick={() => {
            goToHome();
          }}
          size="sm"
          className="d-flex mt-2"
          style={{ maxHeight: "28px" }}
        >
          <FaChevronLeft color="var(--text)" className="m-auto" />
        </Button>
      ) : (
        <div style={{ width: "32px", minWidth: "32px", height: "28px" }} />
      )}
      <div
        className={isMobile ? "w-100 text-start ps-2 " : "mx-auto d-flex"}
        style={{
          height: isMobile ? menuHeight : undefined,
          overflow: "hidden",
        }}
      >
        {isMobile && !open ? (
          <div className="w-100 d-flex mt-0" style={{ height: "28px" }}>
            <div className="mx-auto">
              <LangSelect hideIcon={true} />
            </div>
          </div>
        ) : (
          <div className={isMobile ? "mt-4" : "d-flex mx-auto"}>
            <HomeButton />
            <LangSelect />
            <ProfileButton />
            <LogoutButton />
            <TermsButton />
          </div>
        )}
      </div>
      {isMobile ? (
        <Button
          variant="transparent"
          onClick={() => {
            setOpen(!open);
          }}
          size="sm"
          className="d-flex mt-2"
          style={{ maxHeight: "28px" }}
        >
          <FaBars color="var(--text)" className="m-auto" />
        </Button>
      ) : null}
    </div>
  );
}
