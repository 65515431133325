import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MeditationState } from "./actionTypes";
import IMeditation from "../interfaces/IMeditation";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../config/firebaseConfig";
import IHistoryMeditation from "../interfaces/IHistoryMeditation";
import IFavoritesMeditation from "../interfaces/IFavoritesMeditation";

const initialState: MeditationState = {
  meditation: {} as IMeditation,
  meditations: [],
  freeMeditations: [],
  history: [],
  favorites: [],
};

export const fetchFreeMeditations = createAsyncThunk(
  "meditations/fetchFreeMeditations",
  async (lang: string) => {
    try {
      const meditationsCol = collection(db, "meditations");
      const meditationSnapshot = await getDocs(meditationsCol);
      const meditationList = meditationSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as IMeditation[];
      return meditationList;
    } catch (error: any) {
      console.error(
        "Error get meditation:",
        error.response?.data || error.message || error
      );
    }

    return [];
  }
);

export const fetchMyMeditations = createAsyncThunk(
  "meditations/fetchMyMeditations",
  async (uid: string) => {
    try {
      const meditationsCol = collection(db, `users/${uid}/meditations/`);
      const q = query(meditationsCol, orderBy("date", "desc"), limit(20));
      const meditationSnapshot = await getDocs(q);
      const meditationList = meditationSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as IMeditation[];
      localStorage.setItem("myMeditations", JSON.stringify(meditationList));
      return meditationList;
    } catch (error: any) {
      console.error(
        "Error getting meditations:",
        error.response?.data || error.message || error
      );
      throw error;
    }
  }
);

export const fetchMyMeditation = createAsyncThunk(
  "meditations/fetchMyMeditation",
  async (payload: { uid: string; meditation: string }) => {
    try {
      const meditationDocPath = doc(
        db,
        `users/${payload.uid}/meditations/${payload.meditation}`
      );

      const meditationDoc = await getDoc(meditationDocPath);

      let meditationReseponse = {} as IMeditation;

      if (meditationDoc.exists()) {
        meditationReseponse = {
          id: meditationDoc.id,
          ...meditationDoc.data(),
        } as IMeditation;
      }

      localStorage.setItem("myMeditation", JSON.stringify(meditationReseponse));
      return meditationReseponse;
    } catch (error: any) {
      console.error(
        "Error getting meditations from course list:",
        error.response?.data || error.message || error
      );
      throw error;
    }
  }
);

export const fetchTodayMeditation = createAsyncThunk(
  "meditations/fetchTodayMeditation",
  async (payload: { lang: string }) => {
    try {
      const meditationCollection = collection(
        db,
        `meditations/${payload.lang}/meditations`
      );

      const today = new Date().toISOString().slice(0, 10).replace(/-/g, "/");

      let queryData = query(
        meditationCollection,
        where("dateString", "==", today),
        limit(1)
      );

      let meditationDocs = await getDocs(queryData);

      if (!meditationDocs?.docs?.length) {
        queryData = query(
          meditationCollection,
          orderBy("date", "desc"),
          limit(1)
        );

        meditationDocs = await getDocs(queryData);
      }

      let newMeditation = {} as IMeditation;
      if (meditationDocs.docs?.length > 0) {
        const myDoc = meditationDocs.docs[0];
        if (myDoc.exists()) {
          newMeditation = {
            id: myDoc.id,
            ...myDoc.data(),
          } as IMeditation;

          localStorage.setItem("myMeditation", JSON.stringify(newMeditation));
          return newMeditation;
        }
      }

      return newMeditation;
    } catch (error: any) {
      console.error(
        "Error getting meditations from course list:",
        error.response?.data || error.message || error
      );
      throw error;
    }
  }
);

export const fetchLastMeditation = createAsyncThunk(
  "meditations/fetchLastMeditation",
  async (payload: { lang: string }) => {
    try {
      const meditationCollection = collection(
        db,
        `meditations/${payload.lang}/meditations`
      );

      const queryData = query(
        meditationCollection,
        orderBy("date", "desc"),
        limit(1)
      );

      const meditationDocs = await getDocs(queryData);

      let newMeditation = {} as IMeditation;
      if (meditationDocs.docs?.length > 0) {
        const myDoc = meditationDocs.docs[0];
        if (myDoc.exists()) {
          newMeditation = {
            id: myDoc.id,
            ...myDoc.data(),
          } as IMeditation;

          localStorage.setItem("myMeditation", JSON.stringify(newMeditation));
          return newMeditation;
        }
      }

      return newMeditation;
    } catch (error: any) {
      console.error(
        "Error getting meditations from course list:",
        error.response?.data || error.message || error
      );
      throw error;
    }
  }
);

export const fetchHistory = createAsyncThunk(
  "meditations/fetchHistory",
  async (uid: string) => {
    try {
      const meditationsCol = collection(db, `users/${uid}/history/`);
      const q = query(meditationsCol, orderBy("date", "desc"), limit(20));
      const meditationSnapshot = await getDocs(q);
      const meditationList = meditationSnapshot.docs.map((doc) => {
        const newHistory = {
          id: doc.id,
          ...doc.data(),
        } as IHistoryMeditation;

        newHistory.date = doc.data().date?.toDate();

        return newHistory;
      }) as IHistoryMeditation[];

      localStorage.setItem("myHistory", JSON.stringify(meditationList));
      return meditationList;
    } catch (error: any) {
      console.error(
        "Error getting meditations:",
        error.response?.data || error.message || error
      );
      throw error;
    }
  }
);

export async function addToHistory(uid: string, history: IHistoryMeditation) {
  try {
    const meditationsCol = collection(db, `users/${uid}/history/`);
    await addDoc(meditationsCol, { ...history });
    return;
  } catch (error: any) {
    console.error(
      "Error saving history:",
      error.response?.data || error.message || error
    );
    throw error;
  }
}

export async function addToFavorites(
  uid: string,
  favorite: IFavoritesMeditation
) {
  try {
    const meditationsCol = doc(
      db,
      `users/${uid}/favorites/${favorite.meditation}`
    );
    await setDoc(meditationsCol, { ...favorite });
    return true;
  } catch (error: any) {
    console.error(
      "Error saving history:",
      error.response?.data || error.message || error
    );
    return false;
  }
}

export async function isFavorited(uid: string, meditation: string) {
  try {
    const favoriteDoc = doc(db, `users/${uid}/favorites/${meditation}`);
    return (await getDoc(favoriteDoc)).exists();
  } catch (error: any) {
    console.error(
      "Error saving history:",
      error.response?.data || error.message || error
    );
    return false;
  }
}

export const fetchFavorites = createAsyncThunk(
  "meditations/fetchFavorites",
  async (uid: string) => {
    try {
      const meditationsCol = collection(db, `users/${uid}/favorites/`);
      const q = query(meditationsCol, orderBy("date", "desc"), limit(20));
      const meditationSnapshot = await getDocs(q);
      const meditationList = meditationSnapshot.docs.map((doc) => {
        const newHistory = {
          id: doc.id,
          ...doc.data(),
        } as IFavoritesMeditation;

        newHistory.date = doc.data().date?.toDate();

        return newHistory;
      }) as IFavoritesMeditation[];
      localStorage.setItem("myHistory", JSON.stringify(meditationList));
      return meditationList;
    } catch (error: any) {
      console.error(
        "Error getting meditations:",
        error.response?.data || error.message || error
      );
      throw error;
    }
  }
);

export const meditationSlice = createSlice({
  name: "meditations",
  initialState,
  reducers: {
    setStoreMeditation: (state, action: PayloadAction<IMeditation>) => {
      state.meditation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFreeMeditations.fulfilled, (state, action) => {
      state.freeMeditations = action.payload;
    });
    builder.addCase(fetchMyMeditations.fulfilled, (state, action) => {
      state.meditations = action.payload;
    });
    builder.addCase(fetchHistory.fulfilled, (state, action) => {
      state.history = action.payload;
    });
    builder.addCase(fetchFavorites.fulfilled, (state, action) => {
      state.favorites = action.payload;
    });
    builder.addCase(fetchMyMeditation.fulfilled, (state, action) => {
      state.meditation = action.payload;
    });
    builder.addCase(fetchLastMeditation.fulfilled, (state, action) => {
      state.meditation = action.payload;
    });
    builder.addCase(fetchTodayMeditation.fulfilled, (state, action) => {
      state.meditation = action.payload;
    });
  },
});

export const { setStoreMeditation } = meditationSlice.actions;
export default meditationSlice.reducer;
