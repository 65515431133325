import { UseAppContext } from "../../context/appContext";
import { AppNavbar } from "../navbar/AppNavbar";

interface Props {
  hideBar?: boolean;
  children: React.ReactNode;
}

function AppContainer({ children, hideBar }: Props) {
  const { isMobile } = UseAppContext();

  return (
    <div
      className="main-container d-flex bg-back text-center"
      style={{
        width: "100vw",
        top: "0px",
        left: "0px",
        overflow: "auto",
        transition: "0s",
      }}
    >
      {!hideBar && <AppNavbar />}
      <div
        className="mx-auto d-flex flex-column"
        style={{
          maxWidth: isMobile ? "100vw" : "700px",
          width: "100%",
          minHeight: "calc(100vh)",
          marginTop: "0px",
          zIndex: "20",
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default AppContainer;
