import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import { I18nextProvider } from "react-i18next";
import i18n from "./locales/i18n";
import { Provider } from "react-redux";
import { AppProvider } from "./context/appContext";
import store from "./store/store";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-range-slider-input/dist/style.css";
import { ToastContainer } from "react-toastify";
import { Home } from "./pages/Home";
import { Terms } from "./pages/Terms";
import { ShowMeditation } from "./pages/ShowMeditation";
import { History } from "./pages/History";
import { Favorites } from "./pages/Favorites";
import { Profile } from "./pages/Profile";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const helmetContext = {};

root.render(
  <React.StrictMode>
    <HelmetProvider context={helmetContext}>
      <I18nextProvider i18n={i18n}>
        <AppProvider>
          <Provider store={store}>
            <RouterProvider
              router={createBrowserRouter([
                { path: "*", element: <Home /> },
                { path: "/today", element: <ShowMeditation /> },
                { path: "/timer", element: <ShowMeditation /> },
                { path: "/todayIframe", element: <ShowMeditation /> },
                { path: "/timerIframe", element: <ShowMeditation /> },
                { path: "/terms", element: <Terms /> },
                { path: "/history", element: <History /> },
                { path: "/favorites", element: <Favorites /> },
                { path: "/profile", element: <Profile /> },
              ])}
            />
            <ToastContainer />
          </Provider>
        </AppProvider>
      </I18nextProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
