import AppContainer from "../components/containers/AppContainer";
import { TermContainer } from "../components/terms/TermContainer";

export function Terms() {
  return (
    <AppContainer>
      <>
        <TermContainer />
      </>
    </AppContainer>
  );
}
