import AppContainer from "../components/containers/AppContainer";
import FavoritesContainer from "../components/meditations/favorites/FavoritesContainer";

export function Favorites() {
  return (
    <AppContainer>
      <FavoritesContainer />
    </AppContainer>
  );
}
