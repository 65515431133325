import { FaSignOutAlt } from "react-icons/fa";
import { UseAppContext } from "../../context/appContext";

export function LogoutButton() {
  const { isLogged, translate, logout, isMobile } = UseAppContext();

  if (!isLogged) {
    return null;
  }

  return (
    <div
      className={isMobile ? "py-2 border-bottom d-flex" : "my-auto me-3 px-0"}
    >
      {isMobile && (
        <FaSignOutAlt color="var(--text)" size={12} className="my-auto me-2" />
      )}
      <p
        className={"pointer " + (isMobile ? "mb-0" : "my-auto ")}
        onClick={() => {
          logout();
        }}
      >
        {translate("logout")}
      </p>
    </div>
  );
}
